/* eslint-disable @next/next/no-img-element */
import { useAppDispatch, useAppSelector, usePaginatedContests, useUser } from '@hooks';
import type { NextPage } from 'next';
import Link from 'next/link';
import Loader from '@components/Loader';
import Head from 'next/head';
import { Fragment } from 'react';
import ContestCard from '@components/ContestCard';
import Footer from '@layouts/Footer';
import { getMetaTags } from '@helpers/util';
import { toggleModal } from '@store/ui';
import { Modals } from '@store/ui/types';
import CreateContestModal from '@components/modals/CreateContest';
import Header from "@layouts/Header"

const Landing: NextPage = () => {
  const { user, userIsReady } = useUser({ redirect: false });
  const { contests } = usePaginatedContests({ itemsPerPage: 3 });
  const { ui: { modals } } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const metaTags = getMetaTags({
    isIndex: true,
    title: '',
    description: 'Host and manage your online contests, pageants, choice awards and nominations for FREE!'
  }).map(({ elem, ...props }) => {
    const Elem = elem;
    return elem === 'title'
      ? <title key={props.content}>{props.content}</title>
      : <Elem key={props.name || props.itemProp} {...props} />
  });

  if (!userIsReady) {
    return <Loader></Loader>
  }

  return <>
    <Head>
      {metaTags.map(tag => tag)}
    </Head>

    <Header user={user} isLandingPage={true} />
    <main className="landing-page">
      <section id="hero-section" className="mb-5">
        <div className='image-container'>
          <div className="hero-text container-lg d-flex flex-column h-100 justify-content-center">
            <h3 className="fs-hero-title text-light">
              Your contests never <br /> looked better!
            </h3>
            <p className="fs-6 text-light w-50">
              Manage your online and offline contests, polls, award events and pageants
            </p>
            <div className="text-start">
              <Link href="/contests" className="btn btn-outline-light">
                Explore Contests
              </Link>
            </div>
          </div>
        </div>

        <span className="arrow-down">
          <img src="/assets/icons/arrow-down.svg" alt="down arrow" />
        </span>
      </section>

      <section id="desc" className="mx-md-5 mx-sm-1 mx-xxl-auto">
        <div className="container-xxl row mx-auto justify-content-between align-items-center">
          <div className="col-md-5">
            <div className="image-wrapper">
              <img src="/assets/images/people-circle.svg" alt="showcase of winning contestants" className="img-fluid w-100 h-100" />
            </div>
          </div>
          <div className="col-md-6 text-desc">
            <h3 className="fs-section-title fw-bold mt-5">
              Join organizers around the world in building
              reliable voting experiences
            </h3>
            <p className="pt-3 fs-card-title">
              Need to know the employee of the year?
              or the Most Beautiful Girl in /insert country/? Host your contests and start accepting votes in no time!
            </p>
            <Link href="/contests?create=1" className='btn btn-primary'>
              Get Started
            </Link>
          </div>
        </div>
      </section>

      {contests.length > 0 &&
        <section className='mx-md-5 mx-sm-1 mx-xxl-auto'>
          <div className="container">
            <div className="row">
              <h3 className="fs-section-title fw-bold mt-5 mx-4">Explore contests</h3>
              <div className="container">
                <div className="row px-4 pt-3">
                  {
                    contests.map(contest => (
                      <Fragment key={contest._id}>
                        <ContestCard contest={contest}></ContestCard>
                      </Fragment>
                    ))
                  }
                </div>
                <div className="row">
                  <div className="col text-center">
                    <Link href='/contests' className='btn btn-outline-primary'>
                      Show more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </main>
    <Footer />

    <CreateContestModal
      showModal={modals.createContest.isOpen}
      {...modals.createContest.props}
      toggle={() => dispatch(toggleModal({ name: Modals.CreateContest }))}
    />
  </>;
}

export default Landing



{/* <header>
<Navbar
  dark
  expand="sm"
  className={`px-md-5 position-absolute ${isNavbarOpen ? "bg-primary" : "bg-transparent"}`}
  light>
  <NavbarBrand href="/">
    <Logo />
  </NavbarBrand>
  <NavbarToggler onClick={toggleNavbar} />
  <Collapse isOpen={isNavbarOpen} navbar>
    {/* <Nav
      className="ms-auto"
      navbar>
      {user && <NavItem>
        <NavLink href="/dashboard">
          My dashboard
        </NavLink>
      </NavItem>}

      {!user && <NavItem className='d-flex align-items-center text-muted text-uppercase'>
        <NavLink href="/auth/login">
          Login
        </NavLink>
        |
        <NavLink href="/signup">
          Signup
        </NavLink>
      </NavItem>}
    </Nav> */}

    // <Nav className="ms-auto" navbar>
    //   <NavItem>
    //     <NavLink active={isActive('contests')} href="/contests">
    //       Explore
    //     </NavLink>
    //   </NavItem>

    //   <NavItem>
    //     <NavLink active={isActive('pricing')} href="/pricing">
    //       Pricing
    //     </NavLink>
    //   </NavItem>

    //   <NavItem>
    //     <NavLink active={isActive('dashboard')} href="/dashboard">
    //       My Dashboard
    //     </NavLink>
    //   </NavItem>

    //   {!user && <NavItem className='d-flex align-items-center text-muted text-uppercase'>
    //     <NavLink href="/auth/login">
    //       Login
    //     </NavLink>
    //     |
    //     <NavLink href="/signup">
    //       Signup
    //     </NavLink>
    //   </NavItem>}

    //   <NavItem className={`${isNavbarOpen && 'mt-3'}`}>
    //     <button onClick={() => openCreateContestModal()} className="btn create-contest" aria-current="page">
    //       <Icon name="plus" className='me-1'></Icon>
    //       Create Contest
    //     </button>
    //   </NavItem>

    //   {user && !isNavbarOpen && <>
    //     <a className="nav-item">
    //       <button className="btn p-0 d-flex align-items-center" type="button"
    //         id="profileMenuBtn" aria-expanded="false">
    //         <div className="user-avatar">
    //           <Avatar src={user?.avatar} name={user?.name} size='40' round={true} className='mr-2' />
    //         </div>
    //         <span className="ms-2">
    //           <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    //             <path d="M8.54736 1L4.69831 5L0.849251 1" stroke="white" strokeWidth="1.5" strokeLinecap="round"
    //               strokeLinejoin="round" />
    //           </svg>
    //         </span>
    //       </button>
    //     </a>
    //     <ProfileMenuDropdown
    //       target='profileMenuBtn'
    //       user={user} />
    //   </>}
    // </Nav>
//   </Collapse>
// </Navbar>
// </header> */}
