import React, { PropsWithChildren, useState, useEffect } from "react";
import { Observable, isObservable, Subscription } from "rxjs";

type AsyncProps<T> = PropsWithChildren<{ prefix?: string; suffix?: string; }>;

/** 
 * A generic component for immediately consuming the values
 * of an observable source.
 * Similar to async pipe in Angular */
export default function Async<T = any>({
  children, prefix = '', suffix = ''
}: AsyncProps<T>): JSX.Element {
  const [value, setValue] = useState<T | null>(null);
  useEffect(() => {
    let subscription: Subscription;
    if (isObservable(children)) {
      subscription = (children as Observable<T>).subscribe(setValue);
    }
    return () => subscription.unsubscribe();
  }, [children]);
  return <>{prefix}{value}{suffix}</>;
}
